import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.css";
import "svgxuse";
import "@/assets/sass/style.scss";
import "@/../mobile.js";

/**
 * Извлечение конкретного параметра из URL и его значения.
 *
 * @param {String} name
 * @return {String}
 */
function getUrlParameter(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  let results = regex.exec(location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

/**
 * Если при загрузке страницы была ошибка авторизации - она отобразится сразу.
 */
if (getUrlParameter("error")) {
  document.getElementById("error-message").style.display = "block";
  document.getElementById("error-message").style.opacity = 1;
}

/**
 * Отображение текста для кнопки "Забыли пароль?" и скрытие его по таймеру.
 */
let elementButtonForgotPassword = document.getElementById("btnForgotPassword");
if (elementButtonForgotPassword) {
  elementButtonForgotPassword.addEventListener("click", () => {
    let elementTextForgotPassword = document.querySelector("#authForm p");
    elementTextForgotPassword.classList.toggle("visible");

    setTimeout(() => {
      elementTextForgotPassword.classList.remove("visible");
    }, 7000);
  });
}

/**
 * Переключение видимости на панель авторизации (актуально для узких экранов).
 */
let elementButtonGoToAuthForm = document.getElementById("btnGoToAuthForm");
if (elementButtonGoToAuthForm) {
  elementButtonGoToAuthForm.addEventListener("click", () => {
    document.querySelector("div.guest-container").classList.toggle("hidden");
  });
}

/**
 * Переключение видимости на панель описания услуги (актуально для узких экранов).
 */
let elementButtonGoToConnect = document.getElementById("btnGoToConnect");
if (elementButtonGoToConnect) {
  elementButtonGoToConnect.addEventListener("click", () => {
    document.querySelector("div.guest-container").classList.toggle("hidden");
  });
}

function parseQueryString(query) {
  let vars = query.split("&");
  let query_string = {};
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    let key = decodeURIComponent(pair[0]);
    let value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof query_string[key] === "undefined") {
      query_string[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      query_string[key] = [query_string[key], decodeURIComponent(value)];
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
}

/**
 * Извлечение GET параметров из адресной строки и помещение их в строку URL для отправки формы.
 * Делается это для корректного редиректа после авторизации.
 */
function getAction() {
  if (getUrlParameter("next")) {
    let form = document.getElementById("authForm");
    let query = window.location.search.substring(1);
    let qs = parseQueryString(query);
    form.action = '/api/internal/login/?next=' + qs.next;
  }
}

getAction();

(function (m, e, t, r, i, k, a) {
  m[i] = m[i] || function () {
    (m[i].a = m[i].a || []).push(arguments);
  };
  m[i].l = 1 * new Date();
  k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
})(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
ym(52381435, "init", {id: 52381435, clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true, trackHash: true});
